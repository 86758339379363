import * as React from 'react'
import { Layout } from '../components/layout'
import * as styles from './privacy.module.scss'
import rehypeReact from 'rehype-react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

export default (props: any) => {
  const renderAst = new rehypeReact({
    createElement: React.createElement,
  }).Compiler

  const data = props.data
  const post = data.markdownRemark
  return (
    <Layout>
      <Helmet />
      <section className={styles.text}>{renderAst(post.htmlAst)}</section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      htmlAst
    }
  }
`
